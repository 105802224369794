import api from '../config/api';

const login = async (email: string, password: string) => {
  const response = await api.post('/auth/login', { email, password });
  return response.data;
};

const emailInfo = async (user: any) => {
  const response = await api.post('/auth/forgot-email', user);
  return response.data;
};

const passwordResetToken = async (user: any) => {
  const response = await api.post('/auth/forgot-password', user);
  return response.data;
};

const logout = async () => {
  try {
    await api.post('/auth/logout');
  } catch (error: any) {
    console.error('Erro durante o logout:', error);
  } finally {
    localStorage.clear();
    window.location.href = '/';
  }
};

const getUserInfo = async () => {
  try {
    if (localStorage.getItem('isAuthenticated') === 'true') {
      const response = await api.get('/users/me');
      return response.data;
    } else {
      return { success: false };
    }
  } catch (error: any) {
    localStorage.removeItem('isAuthenticated');
    throw error;
  }
};

const getUserActivateInfo = async (token: string) => {
  let response;
  try {
    response = await api.get(`/auth/activate/${token}`);
    return response.data;
  } catch (error: any) {
    console.log(error.response.data);
    throw error.response.data.message;
  }
};

const apiMethods = {
  login,
  emailInfo,
  passwordResetToken,
  logout,
  getUserInfo,
  getUserActivateInfo,
};
export default apiMethods;

import React, { useState, useEffect } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import Loading from '../../../components/Loading';
import { FormSignup } from '../../../components/Forms';

const SignupPage: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    document.title = 'Beeventz APP - Cadastro de Usuário';

    const checkAuth = async () => {
      setIsLoading(true);
      if (localStorage.getItem('isAuthenticated') === 'true') {
        window.location.href = '/home';
      }
      setIsLoading(false);
    };

    checkAuth();
  }, []);
  return (
    <div className="bg-signup">
      <Container>
        <Row className="min-vh-100 m-0">
          <Col
            xs={12}
            className="d-flex justify-content-center align-items-center"
          >
            <FormSignup setIsLoading={setIsLoading} />
          </Col>
        </Row>
      </Container>
      <Loading show={isLoading} />
    </div>
  );
};

export default SignupPage;

import React from 'react';

interface LoadingProps {
  show: boolean;
}

const Loading: React.FC<LoadingProps> = ({ show }) => {
  return (
    <div className={`loading ${show ? 'open' : ''}`}>
      <div />
    </div>
  );
};

export default Loading;

import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from 'react-router-dom';
import PrivateRoute from './config/privateRoute';
import ActivatePage from './pages/Auth/Activate';
import LoginPage from './pages/Auth/Login';
import SignupPage from './pages/Auth/Signup';
import HomePage from './pages/Internal/Home';
import DashboardPage from './pages/Internal/Dashboard';

const AppRoutes: React.FC = () => {
  const returnToLoginPage = () => {
    return <Navigate to="/" />;
  };
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route path="/cadastro" element={<SignupPage />} />
        <Route path="/ativar/:token" element={<ActivatePage />} />
        <Route
          path="/home"
          element={
            <PrivateRoute>
              <HomePage />
            </PrivateRoute>
          }
        />
        <Route
          path="/dashboard"
          element={
            <PrivateRoute>
              <DashboardPage />
            </PrivateRoute>
          }
        />

        <Route path="*" element={returnToLoginPage()} />
      </Routes>
    </Router>
  );
};
export default AppRoutes;

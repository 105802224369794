import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Row, Col, Container, Card } from 'react-bootstrap';
import Loading from '../../../components/Loading';
import authService from '../../../services/authService';
import { ButtomCustom } from '../../../components/Buttons';

const ActivatePage: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [item, setItem] = useState({
    fullName: '',
  });
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const { token } = useParams() as any;

  useEffect(() => {
    document.title = 'Beeventz APP - Ativação de Cadastro';

    const checkAuth = async () => {
      setIsLoading(true);
      if (localStorage.getItem('isAuthenticated') === 'true') {
        window.location.href = '/home';
      }
      setIsLoading(false);
    };

    const searchByToken = async (token: string) => {
      setIsLoading(true);
      await authService
        .getUserActivateInfo(token)
        .then(response => {
          setItem(response.user);
        })
        .catch(error => {
          setError(true);
          setErrorMessage(error);
        });
      setIsLoading(false);
    };

    checkAuth();
    searchByToken(token);
  }, [token]);

  return (
    <div className="bg-signup">
      <Container>
        <Row className="min-vh-100 m-0">
          <Col
            xs={12}
            className="d-flex justify-content-center align-items-center"
          >
            <Card className="card-form py-1 px-2 card-invitation">
              <Card.Body className="body-invitation">
                <Row>
                  {error ? (
                    <Col xs={12} md={12}>
                      <div className="d-flex justify-content-center align-items-center mb-4">
                        <span className="material-icons text-danger text-center fw-bold">
                          warning
                        </span>
                      </div>
                      <h4 className="fw-bold">{errorMessage}</h4>
                      <h5>Por favor, entre em contato com o suporte.</h5>
                    </Col>
                  ) : (
                    <Col xs={12} md={12}>
                      <div className="d-flex justify-content-center align-items-center mb-4">
                        <span className="material-icons text-success text-center fw-bold">
                          check_circle
                        </span>
                      </div>
                      <h4 className="fw-bold">
                        Olá,{' '}
                        <span className="color-primary">{item.fullName}</span>!
                        Sua conta foi ativada com sucesso.
                      </h4>
                    </Col>
                  )}
                  <Col xs={12} className="d-flex justify-content-center mt-4">
                    <ButtomCustom
                      onClick={() => (window.location.href = '/')}
                      btnText="ir para login"
                    />
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <Loading show={isLoading} />
    </div>
  );
};

export default ActivatePage;

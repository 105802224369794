import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Loading from '../../../components/Loading';
import { FormLogin } from '../../../components/Forms';

const LoginPage: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    document.title = 'Beeventz APP - Login';

    const checkAuth = async () => {
      setIsLoading(true);
      if (localStorage.getItem('isAuthenticated') === 'true') {
        window.location.href = '/home';
      }
      setIsLoading(false);
    };

    checkAuth();
  }, []);
  return (
    <div className="bg-login">
      <Container>
        <Row className="min-vh-100 m-0">
          <Col
            xs={12}
            className="d-flex justify-content-end align-items-center"
          >
            <FormLogin setIsLoading={setIsLoading} />
          </Col>
        </Row>
      </Container>
      <Loading show={isLoading} />
    </div>
  );
};

export default LoginPage;

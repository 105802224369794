import { Navigate } from 'react-router-dom';
import { useAuth } from '../context/authContext';

function PrivateRoute({
  children = <></>,
  adminOnly = false,
  requireCompany = false,
  companyAdminOnly = false,
  requireOwner = false,
}) {
  const { user, selectedCompany } = useAuth();

  if (!user) {
    return <Navigate to="/" replace />;
  }

  if (adminOnly && user.role !== 'admin') {
    return <Navigate to="/home" replace />;
  }

  if (requireCompany && !selectedCompany) {
    return <Navigate to="/home" replace />;
  }

  if (
    companyAdminOnly &&
    !(
      user?.companyRole === 'admin' ||
      user?.companyRole === 'owner' ||
      user.role === 'admin'
    )
  ) {
    return <Navigate to="/home" replace />;
  }

  if (
    requireOwner &&
    !(user?.companyRole === 'owner' || user.role === 'admin')
  ) {
    return <Navigate to="/home" replace />;
  }

  return children;
}

export default PrivateRoute;

import { useState } from 'react';
import { Row, Col, Card, Form, InputGroup, Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { ButtonPrimary } from '../../Buttons';
import userService from '../../../services/userService';
import Swal from 'sweetalert2';

interface FormProps {
  setIsLoading: (isLoading: boolean) => void;
}

function FormSignup({ setIsLoading }: FormProps) {
  const [showPassword, setShowPassword] = useState(false);
  const [user, setUser] = useState({
    email: '',
    password: '',
    cpf: '',
    fullName: '',
  });
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const startSubmitProcess = () => {
    setError(false);
    setErrorMessage('');
    setIsLoading(true);
  };

  const handleInputChange = (event: any) => {
    const { name, value } = event.target;
    setUser(prevUser => ({
      ...prevUser,
      [name]: value,
    }));
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    startSubmitProcess();

    if (user.password.length < 8) {
      setError(true);
      setErrorMessage('Sua senha deve ter pelo menos 8 caracteres.');
      setIsLoading(false);
      return;
    }

    await userService
      .signUp(user)
      .then(data => {
        setIsLoading(false);
        if (data.success) {
          Swal.fire({
            icon: 'success',
            title: 'Solicitação de cadastro concluída!',
            text: 'Enviamos um e-mail solicitando a confirmação da sua conta.',
            showConfirmButton: false,
            timer: 6000,
          }).then(() => {
            window.location.href = '/';
          });
        }
      })
      .catch((err: any) => {
        setIsLoading(false);
        setError(true);
        setErrorMessage(
          err.response && err.response.data
            ? err.response.data.message
            : 'Estamos enfrentando problemas para realizar essa ação. Por gentileza tente novamente mais tarde.'
        );
        console.error('Login error:', err);
      });
  };

  return (
    <Card className="card-form card-signup">
      <Row className="m-0">
        <Col xs={12} className="padding-card">
          <h1 className="font-bold mb-0">Cadastrar</h1>
          <p className="font-light color-dark-gray mb-4">
            Digite suas informações e crie uma conta!
          </p>
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col xs={12} md={6} className="mb-3">
                <Form.Group>
                  <Form.Label>
                    Nome
                    <sup className="ms-1 text-danger fw-bold">*</sup>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="fullName"
                    defaultValue={user.fullName}
                    onChange={handleInputChange}
                    required
                  />
                </Form.Group>
              </Col>
              <Col xs={12} md={6} className="mb-3">
                <Form.Group>
                  <Form.Label>
                    CPF
                    <sup className="ms-1 text-danger fw-bold">*</sup>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="cpf"
                    defaultValue={user.cpf}
                    onChange={handleInputChange}
                    required
                  />
                </Form.Group>
              </Col>
              <Col xs={12} md={6} className="mb-3">
                <Form.Group>
                  <Form.Label>
                    E-mail
                    <sup className="ms-1 text-danger fw-bold">*</sup>
                  </Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    defaultValue={user.email}
                    onChange={handleInputChange}
                    required
                  />
                </Form.Group>
              </Col>
              <Col xs={12} md={6} className="mb-3">
                <Form.Group>
                  <Form.Label>
                    Senha
                    <sup className="ms-1 text-danger fw-bold">*</sup>
                  </Form.Label>
                  <InputGroup>
                    <Form.Control
                      type={showPassword ? 'text' : 'password'}
                      name="password"
                      defaultValue={user.password}
                      onChange={handleInputChange}
                      required
                    />
                    <InputGroup.Text
                      onClick={() => setShowPassword(!showPassword)}
                      className="c-pointer"
                    >
                      <span
                        className={`material-icons me-1 visibility-icon ${showPassword ? 'visible' : 'hidden'}`}
                      >
                        {showPassword ? 'visibility_off' : 'visibility'}
                      </span>
                    </InputGroup.Text>
                  </InputGroup>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              {error && (
                <Col xs={12} className="mt-3 text-center small">
                  <Alert variant={'danger'}>
                    <strong>{errorMessage}</strong>
                  </Alert>
                </Col>
              )}
              <Col
                xs={12}
                className="d-flex flex-column flex-md-row justify-content-center justify-content-md-end"
              >
                <ButtonPrimary
                  type="submit"
                  btnText="CRIAR MINHA CONTA"
                  className={'w-100'}
                />
              </Col>
              <Col xs={12} className="mt-3 mb-0 text-center small">
                <Link to="/" className="text-uppercase color-primary c-pointer">
                  Já tem uma conta? Acesse aqui
                </Link>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Card>
  );
}

export default FormSignup;

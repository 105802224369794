import { Button } from 'react-bootstrap';

type ButtonProps = {
  btnText?: string;
  className?: string;
  type?: 'button' | 'submit' | 'reset' | undefined;
  disabled?: boolean;
  onClick?: () => void;
};

function ButtonHeader(btnText: string, onClick: () => void) {
  return (
    <Button className="custom-btn" onClick={onClick}>
      <span>{btnText}</span>
    </Button>
  );
}

function ButtonPrimary({
  btnText,
  className,
  type = 'button',
  disabled,
}: ButtonProps) {
  return (
    <Button type={type} className={className} disabled={disabled}>
      <span className="text-uppercase">{btnText}</span>
    </Button>
  );
}

function ButtonCallToACtion({
  btnText,
  className,
  type = 'button',
  onClick,
}: ButtonProps) {
  return (
    <Button
      className={`btn-call-to-action ` + className}
      type={type ? type : 'button'}
      onClick={onClick}
    >
      <span className="text-uppercase">{btnText}</span>
    </Button>
  );
}

function ButtonFooter(btnText: string, onClick: () => void) {
  return (
    <Button className="custom-btn-primary align-self-center" onClick={onClick}>
      <span>{btnText}</span>
    </Button>
  );
}

function ButtomCustom({ btnText, onClick }: ButtonProps) {
  return (
    <Button className="custom-btn" onClick={onClick}>
      <span className="text-uppercase">{btnText}</span>
    </Button>
  );
}

export {
  ButtonHeader,
  ButtonPrimary,
  ButtonCallToACtion,
  ButtonFooter,
  ButtomCustom,
};

import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faQrcode,
  faBars,
  faBell,
  faArrowRightFromBracket,
  faHouse,
  faUsers,
  faChartSimple,
} from '@fortawesome/free-solid-svg-icons';
import { faCircleQuestion } from '@fortawesome/free-regular-svg-icons';
import {
  Navbar,
  Nav,
  Accordion,
  Card,
  Container,
  Row,
  Col,
  Button,
  Dropdown,
  DropdownButton,
} from 'react-bootstrap';
import LogoFooter from '../../assets/img/logo-footer.png';
import BeeThumbsUp from '../../assets/img/bee_thumbs_up.webp';
import authService from '../../services/authService';
import { useAuth } from '../../context/authContext';

// type CollapseState = {
//   [key: string]: boolean;
// };

function Sidebar({ children }: any) {
  const { user, selectedCompany } = useAuth();
  const [sidebar, setSidebar] = useState(true);
  // const [collapses, setCollapses] = useState<CollapseState>({});
  const toggleSidebar = () => setSidebar(!sidebar);

  // const handleCollapse = (id: string) => {
  //   setCollapses(prev => ({ ...prev, [id]: !prev[id] }));
  // };

  return (
    <>
      <div className={sidebar ? 'd-flex' : `d-flex toggled`} id="wrapper">
        <div id="sidebar-wrapper" className="d-none d-lg-block">
          <div className="sidebar-heading d-flex align-items-center justify-content-center flex-column">
            <Link
              to="/home"
              className="d-flex align-content-center justify-content-center text-decoration-none"
            >
              {' '}
              <img
                src="https://fakeimg.pl/250x250/fff/d8401e/?text=beeventz"
                alt=""
                className="img-fluid mb-2 circle-img"
              />
            </Link>
          </div>
          {(user?.role === 'admin' || selectedCompany) && (
            <div className="d-flex justify-content-center mb-3">
              <Button className="btn btn-qrcode">
                <FontAwesomeIcon icon={faQrcode} className="me-2" />
                LEITOR DE QR CODE
              </Button>
            </div>
          )}
          <Accordion className="mb-2 mb-md-5">
            {/* DASHBOARD */}
            <Card className="border-0">
              <Card.Header className="p-0 border-0">
                <NavItem icon={faHouse} title={'Home'} href="/home" />
                {/* ADMIN */}
                {user?.role === 'admin' && !selectedCompany && (
                  <>
                    <NavItem
                      icon={faChartSimple}
                      title={'Dashboard'}
                      href="/dashboard"
                    />
                    <NavItem
                      icon={faUsers}
                      title={'Usuários'}
                      href="/usuarios"
                    />
                  </>
                )}
                {/* <NavItem icon={faCalendar} title={'Eventos'} href="/events" />
                <NavItem icon={faTicket} title={'Ingressos'} href="/tickets" />
                <NavItem
                  icon={faDollarSign}
                  title={'Financeiro'}
                  href="/finances"
                />
                <NavItem
                  icon={faChartPie}
                  title={'Relatórios'}
                  href="/reports"
                />
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <NavItem
                      icon={faHandshake}
                      title={'Parceiros'}
                      type="collapse"
                      onClick={() => handleCollapse('partners')}
                      collapsed={collapses['partners']}
                    />
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="d-flex flex-column color-gray">
                      <Link to={'/administrativo/agencias'}>item 1</Link>
                      <Link to={'/administrativo/contratos'}>item 2</Link>
                      <Link to={'/administrativo/gastos-extras'}>item 3</Link>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <NavItem icon={faUsers} title={'Usuários'} href="/users" />
                <NavItem icon={faUserTie} title={'Equipe'} href="/team" />
                <NavItem
                  icon={faBell}
                  title={'Notificações'}
                  href="/notifications"
                />
                <NavItem
                  icon={faCircleQuestion}
                  title={'Suporte'}
                  href="/support"
                /> */}
              </Card.Header>
            </Card>
          </Accordion>
          <div className="sidebar-logout-container">
            <hr className="hr-sidebar" />
            <LogoutItem
              icon={'logout'}
              name={'Logout'}
              className={'c-pointer'}
              onClick={() => authService.logout()}
            />
          </div>
        </div>
        <div
          id="page-content-wrapper"
          className="d-flex justify-content-between flex-column"
        >
          <div className="d-flex flex-column w-100 h-100 overflow-auto justify-content-between">
            <div>
              <Navbar
                collapseOnSelect
                expand="lg"
                className="py-3 bg-primary justify-content-between color-white"
              >
                <div className="ms-3 d-flex justify-content-center align-items-center">
                  <FontAwesomeIcon
                    icon={faBars}
                    onClick={toggleSidebar}
                    className="c-pointer"
                    style={{ fontSize: '22px' }}
                  />
                </div>
                <Nav className="me-3 nav-items align-items-center">
                  {(user?.role === 'admin' || selectedCompany) && (
                    <FontAwesomeIcon icon={faQrcode} className="c-pointer" />
                  )}
                  <FontAwesomeIcon
                    icon={faCircleQuestion}
                    className="c-pointer"
                  />
                  <FontAwesomeIcon icon={faBell} className="c-pointer" />
                  <DropdownButton
                    align={{ lg: 'end' }}
                    title={
                      <>
                        <img
                          src={BeeThumbsUp}
                          alt="avatar-user"
                          className="rounded-circle"
                        />
                      </>
                    }
                    id="dropdown-menu-align-right"
                    className="p-0 d-none d-sm-flex"
                  >
                    <Dropdown.Item
                      eventKey="4"
                      onClick={() => authService.logout()}
                    >
                      Sair
                    </Dropdown.Item>
                  </DropdownButton>
                </Nav>
              </Navbar>
              <div className="p-4 background-white">{children}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer">
        <Container>
          <Row>
            <Col xs={12} className="d-flex justify-content-center mb-4">
              <img src={LogoFooter} alt="logo-footer" />
            </Col>
          </Row>
          <Row>
            <Col xs={12} className="d-flex justify-content-center">
              <p className="color-dark-gray m-0">
                © {new Date().getFullYear()} - Beeventz. Todos os Direitos
                Reservados.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

function NavItem({ href = '#', icon, title, type, collapsed, onClick }: any) {
  const location = useLocation();
  const isActive = location.pathname === href;

  return (
    <div className="list-group list-group-flush">
      <Link
        className={`list-group-item list-group-item-action ${isActive ? 'item-active' : ''}`}
        to={href}
        onClick={onClick}
      >
        <div className="d-flex justify-content-between align-items-center">
          <span className="d-flex align-items-center ms-3">
            <div className="icon-container">
              <FontAwesomeIcon icon={icon} className="font-awesome" />
            </div>
            <span className="name-list">{title}</span>
          </span>
          {type === 'collapse' ? (
            collapsed ? (
              <span className="material-icons me-1">keyboard_arrow_up</span>
            ) : (
              <span className="material-icons me-1">keyboard_arrow_down</span>
            )
          ) : (
            <span className="material-icons me-1">chevron_right</span>
          )}
        </div>
      </Link>
    </div>
  );
}

function LogoutItem(props: any) {
  const { onClick } = props;

  return (
    <div className={`list-group list-group-flush ${props.className}`}>
      <div className="list-group-item list-group-item-action" onClick={onClick}>
        <span className="d-flex align-items-center ms-3">
          <div className="icon-container">
            <FontAwesomeIcon
              icon={faArrowRightFromBracket}
              className="font-awesome"
            />
          </div>
          <span className="name-list">Sair</span>
        </span>
      </div>
    </div>
  );
}

export default Sidebar;

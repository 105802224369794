import {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from 'react';
import authService from '../services/authService';
import companyService from '../services/companyService';
import Loading from '../components/Loading';

interface User {
  companies: Company[];
  role: string;
  companyRole?: string;
}

interface Company {
  _id: string;
  fullName: string;
  legalName: string;
}

interface AuthContextType {
  user: User | null;
  selectedCompany: Company | null;
  setUser: (user: User | null) => void;
  setSelectedCompany: (company: Company | null) => void;
  changeSelectedCompany: (companyId: string) => Promise<void>;
  changeSelectedCompanyAdmin: (item: Company) => Promise<void>;
  isLoading: boolean;
  error: any;
}

const defaultContextValue: AuthContextType = {
  user: null,
  selectedCompany: null,
  setUser: () => {},
  setSelectedCompany: () => {},
  changeSelectedCompany: async () => {},
  changeSelectedCompanyAdmin: async () => {},
  isLoading: false,
  error: null,
};

const AuthContext = createContext<AuthContextType>(defaultContextValue);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = useState<User | null>(null);
  const [selectedCompany, setSelectedCompany] = useState<Company | null>(() => {
    const savedCompany = localStorage.getItem('selectedCompany');
    return savedCompany ? JSON.parse(savedCompany) : null;
  });
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadUser = async () => {
      try {
        const userInfo = await authService.getUserInfo();
        setUser(userInfo.user);
        setInitialCompany(userInfo.user);
      } catch (error: any) {
        console.error(error);
        setError(error);
        localStorage.clear();
        setUser(null);
        setSelectedCompany(null);
      } finally {
        setIsLoading(false);
      }
    };

    const setInitialCompany = async (user: any) => {
      setIsLoading(true);
      if (user && !selectedCompany) {
        if (
          user.role !== 'admin' &&
          user.companies &&
          user.companies.length > 0 &&
          !selectedCompany
        ) {
          await companyService.selectCompany(user.companies[0]._id);
          updateSelectedCompany(user.companies[0]);
        } else if (
          user.role === 'admin' &&
          user.companies &&
          user.companies.length > 0
        ) {
          updateSelectedCompany(user.companies[0]);
        } else {
          updateSelectedCompany(null);
        }
      }
      setIsLoading(false);
    };

    loadUser();
  }, [selectedCompany]);

  const updateSelectedCompany = (company: any) => {
    setIsLoading(true);
    if (company) {
      setSelectedCompany(company);
      localStorage.setItem('selectedCompany', JSON.stringify(company));
    } else {
      localStorage.removeItem('selectedCompany');
    }
    setIsLoading(false);
  };

  const changeSelectedCompany = async (companyId: string) => {
    setIsLoading(true);
    const company = user?.companies?.find((c: Company) => c._id === companyId);
    if (company) {
      setSelectedCompany(company);
      localStorage.setItem(
        'selectedCompany',
        JSON.stringify({
          fullName: company.fullName,
          legalName: company.legalName,
          _id: company._id,
        })
      );

      await companyService.selectCompany(companyId).then(() => {
        window.location.replace('/home');
      });
    }
    setIsLoading(false);
  };

  const changeSelectedCompanyAdmin = async (item: any) => {
    setIsLoading(true);
    if (item && item._id) {
      setSelectedCompany({
        fullName: item.fullName,
        legalName: item.legalName,
        _id: item._id,
      });

      localStorage.setItem(
        'selectedCompany',
        JSON.stringify({
          fullName: item.fullName,
          legalName: item.legalName,
          _id: item._id,
        })
      );

      await companyService.selectCompany(item._id).then(() => {
        window.location.replace('/home');
      });
    } else {
      setSelectedCompany(null);
      localStorage.removeItem('selectedCompany');
      if (item && item._id) {
        await companyService.selectCompany(item._id).then(() => {
          window.location.replace('/home');
        });
      }
    }
    setIsLoading(false);
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        selectedCompany,
        setSelectedCompany,
        changeSelectedCompany,
        changeSelectedCompanyAdmin,
        isLoading,
        error,
      }}
    >
      {!isLoading ? children : <Loading show={isLoading} />}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

import api from '../config/api';

const signUp = async (user: any) => {
  const response = await api.post('/auth/signup', user);
  return response.data;
};

const apiMethods = {
  signUp,
};

export default apiMethods;

import api from '../config/api';

const selectCompany = async (id: string) => {
  try {
    const response = await api.post(`/companies/${id}/select`);
    return response.data;
  } catch (error: any) {
    console.log(error.response.data);
    throw error.response.data;
  }
};

const apiMethods = {
  selectCompany,
};
export default apiMethods;

import React from 'react';
import Sidebar from '../../../components/Sidebar';

const HomePage: React.FC = () => {
  return (
    <Sidebar pageName="Dashboard" pageUrl="/">
      <h1>Home Page</h1>
      <p>Welcome to the home page!</p>
    </Sidebar>
  );
};

export default HomePage;
